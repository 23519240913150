import { defineStore } from 'pinia'
import Vue from 'vue'

import { use_actor } from './actor_store'
import { use_dcc } from './dcc_store'
import { use_employee_basic } from './employee_basic_store'
import { use_human_is_idle } from './human_is_idle_store'
import { use_services } from './services_store'
import { use_sidemenu_counters } from './sidemenu_counters_store'
import { use_sidemenu_rules } from './sidemenu_rules_store'
import { use_slot_defs } from './slot_defs_store'
import { use_smcb_gym } from './smcb_gym_store'

function flatten_menu(item) {
  let items = []
  if (item.show || (item.show_rule && use_sidemenu_rules().show_menu[item.show_rule])) {
    if (item.url) items.push({ path: item.url })

    const submenu = item.submenu || []
    if (item.has_submenu) {
      submenu.forEach(subitem => {
        items = items.concat(flatten_menu(subitem))
      })
    } else if (item.stealth_children) {
      items = items.concat(submenu.map(si => ({ path: si.url })))
    }
  }
  return items
}

export const use_entry_point = defineStore('entry_point', {
  state: () => ({
    // MENU MANAGEMENT
    last_sidemenu_url: null,
    pathname: window.location.pathname,
    menu_loaded_successfully: null, // Possible values: null/true/false
    menustructure: null,

    //
    // FROM FRED
    //
    cloudfront: null,
    gym: null,
    logo: null,
    opening_hours: null,
    flippers: null,
  }),

  actions: {
    async update_opening_hours(opening_hours) {
      const { data } = await Vue.smcb_axios.patch(this.settings_base_url, { gym: this.gym, opening_hours })
      this.opening_hours = data.opening_hours
    },

    async load(gym_user, db_agreements, paths, build_menustructure) {
      try {
        const use = {
          actor: use_actor(),
          dcc: use_dcc(),
          employee_basic: use_employee_basic(),
          human_is_idle: use_human_is_idle(),
          services: use_services(),
          sidemenu_counters: use_sidemenu_counters(),
          slot_defs: use_slot_defs(),
          smcb_gym: use_smcb_gym(),
        }

        // Detect if there is a service name in the current url path
        const match_service = window.location.pathname.match(/sn\/([\w-]+)/)
        const service_name = match_service ? match_service[1] : null
        const qs = service_name ? `service_name=${service_name}` : ''
        const url = `${use.smcb_gym.base_url}/sidemenu?${qs}`

        // Don't load same sidemenu data twice
        if (this.last_sidemenu_url === url) {
          return
        }

        this.menu_loaded_successfully = null
        this.last_sidemenu_url = url

        const { data } = await Vue.smcb_axios.get(url)
        use.actor.employee = data.employee
        use.smcb_gym.gym_options = data.gym_options
        use.smcb_gym.settings = data.settings
        use.smcb_gym.services_setup_progress = data.services_setup_progress
        use.smcb_gym.flags = data.flags
        use.employee_basic.set(data.employees)
        use.services.set_all(data.services)

        // For debugging
        window.vl_use = use

        if (data.of_service) {
          use.dcc.test_kits = data.of_service.dcc_test_kits
          use.services.set_selected(data.services.find(s => s.id === data.of_service.service_id))
          use.services.of_selected.check_in_clock = data.of_service.check_in_clock
          use.services.of_selected.flags = data.of_service.flags
          use.services.of_selected.integration_hooks = data.of_service.integration_hooks
          use.services.of_selected.integration_objects = data.of_service.integration_objects
          use.services.of_selected.participants_exist = data.of_service.participants_exist
          use.services.of_selected.settings = data.of_service.settings
          use.services.of_selected.test_cost = data.of_service.test_cost || {}
          use.slot_defs.set_selected(data.of_service.slot_defs)
          use.services.set_areas(data.of_service.areas)
        }

        if (data.human_is_idle_timeout) use.human_is_idle.idle_timeout = data.human_is_idle_timeout

        // Update sidemenu counters
        use.sidemenu_counters.set_booking_counters(data.booking_counters)
        Vue.set(use.sidemenu_counters.counters, 'prescriptions_new', data.prescriptions.new_order_count)

        this.menustructure = build_menustructure(gym_user, db_agreements, paths)
        this.menu_loaded_successfully = true
      } catch (e) {
        console.log('Error loading menu!', e)
        this.menu_loaded_successfully = false
        this.gym_options = {} // To avoid JS errors (see sidemenu_rules_store.js)
      }
    },

    async update_gym(gym) {
      const { data } = Vue.smcb_axios.patch(this.settings_base_url, { gym })
    },

    async upload_location_logo({ form_data }) {
      const url = `${this.settings_base_url}/logo`
      const { data } = await Vue.smcb_axios.post(url, form_data)
      return data
    },

    async legacy_save_location_settings({ gym }) {
      const { data } = await Vue.smcb_axios.patch(this.settings_base_url, { gym })
      this.gym = data.gym
      return data
    },

    async set_virtual(virtual) {
      const { data } = await Vue.smcb_axios.patch(`${this.fred_base_url}/manage/virtual`, { virtual })
      this.gym = data.gym
      return data
    },

    async set_published(published) {
      const { data } = await Vue.smcb_axios.patch(`${this.fred_base_url}/manage/publish`, { published })
      this.gym = data.gym
      return data
    },
  },

  getters: {
    fred_base_url: state => `/${window.VL_CURRENT_APP}/${state.gym.slug}`,
    settings_base_url: state => `/${window.VL_CURRENT_APP}/${state.gym.slug}/settings`,

    thumb_image_url: state => path => `${state.cloudfront}${path}_thumb.jpg`,

    menuitems: state => {
      let items = []
      if (state.menustructure) {
        state.menustructure.forEach(item => {
          items = items.concat(flatten_menu(item))
        })
      }
      return items
    },
  },
})
